<template>
  <mobile-screen :header="true" screen-class="profile-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header">
        <template v-slot:left>
          <router-link :to="{ name: 'r_translations-select-page' }">
            <svg-icon icon="arrow-left"></svg-icon>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("translations", "labels", "title") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('translations', 'labels', 'search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>
    <ul class="named-list" v-if="listOfLabels && listOfLabels.length">
      <li class="name-wrapper">
        <p class="tiny-label bg">
          {{ displayLabelName("translations", "labels", "label") }}
        </p>
        <ul class="bordered-list">
          <li class="list-item" v-for="label in listOfLabels" :key="label.id">
            <div class="checkbox tiny block">
              <input
                type="radio"
                :id="`label${label.id}`"
                name="label"
                :checked="label.id === selectedLabel"
                :value="label.id"
                @change="selectPage(label.id)"
              />
              <label :for="`label${label.id}`">
                <svg-icon icon="checkmark-strait"></svg-icon>
                {{ label.content }}</label
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedLabel || processing"
              @click="selectSinglePage"
            >
              {{ displayLabelName("translations", "labels", "select") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import translationsMixin from "@/services/mixins/translations/translations-mixin";

export default {
  name: "Labels",
  data() {
    return {
      selectedLabel: 0,
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("translation", ["labels"]),
    listOfLabels() {
      const query = this.searchQuery;
      if (this.labels && this.labels.data && this.labels.data.length) {
        if (query && query.length > 1) {
          return this.labels.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.labels.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("translation", ["setSelectedLabel"]),
    selectPage(id) {
      this.selectedLabel = id;
    },
    selectSinglePage() {
      this.setSelectedLabel(
        this.labels.data.filter(item => item.id === this.selectedLabel)
      );
      this.$router.push({ name: "r_selected-label" });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [translationsMixin]
};
</script>
